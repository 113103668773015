<template>
  <div>
    <v-card>
      <v-card-title> ข้อมูลออเดอร์ </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <div>
              <span class="font-weight-bold">หมายเลขออเดอร์ :</span>
              {{ order_detail.order_num }}
            </div>
            <div><span class="font-weight-bold">วันที่ :</span> {{ order_detail.order_date }}</div>
          </v-col>
          <v-col>
            <div><span class="font-weight-bold"> ลูกค้า :</span> {{ user.fname }} {{ user.lname }}</div>
            <div><span class="font-weight-bold">เบอร์โทร :</span> {{ user.phone }}</div>
          </v-col>
        </v-row>
        <v-card outlined class="mt-5">
          <v-card-text>
            <div class="font-weight-bold">รายการข้อมูลออเดอร์</div>
          </v-card-text>
          <v-card-text>
            <div v-for="(order, index) in order_detail.order_list" :key="index">
              <v-row>
                <v-col>
                  <div class="font-weight-bold">{{ order.order_name }}</div>
                </v-col>
                <v-col>
                  <div>ราคา/หน่วย</div>
                  <div class="font-weight-bold pt-2">{{ formatFloat(order.unit_price) }} ฿</div>
                </v-col>
                <v-col>
                  <div>จำนวน</div>
                  <div class="font-weight-bold pt-2">{{ order.amount }}</div>
                </v-col>
                <v-col>
                  <div>ราคารวม</div>
                  <div class="font-weight-bold pt-2">{{ formatFloat(order.total_price) }} ฿</div>
                </v-col>
              </v-row>
              <v-divider class="my-3"></v-divider>
            </div>
            <table>
              <tr>
                <td class="pr-15"><div class="font-weight-bold pt-2">ราคารวม</div></td>
                <td>
                  <div class="font-weight-bold pt-2">{{ formatFloat(order_detail.total_all) }} ฿</div>
                </td>
              </tr>
              <tr>
                <td class="pr-15" style="width: 280px">
                  <div class="font-weight-bold pt-2">
                    ภาษีมูลค่าเพิ่ม
                    {{
                      this.order_detail.vat_type == 0 ? '' : this.order_detail.vat_type == 1 ? '(inc) 7%' : '(exc) 7%'
                    }}
                  </div>
                </td>
                <td>
                  <div class="font-weight-bold pt-2">{{ formatFloat(order_detail.vat_price) }} ฿</div>
                </td>
              </tr>
              <tr>
                <td class="pr-15">
                  <div class="font-weight-bold pt-2">จ่ายไปแล้ว</div>
                </td>
                <td>
                  <div class="font-weight-bold pt-2">{{ formatFloat(this.order_detail.total_pay) }} ฿</div>
                </td>
              </tr>

              <tr>
                <td class="pr-15">
                  <div class="font-weight-bold pt-2">ค้างชำระ</div>
                </td>
                <td>
                  <div class="font-weight-bold pt-2">{{ formatFloat(this.order_detail.remain) }} ฿</div>
                </td>
              </tr>
            </table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <v-card class="my-3">
      <v-card-title> ข้อมูลออเดอร์ </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          :search="search"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          hide-default-footer
          disable-sort
        >
          <template v-slot:no-data>
            <span class="text-center">ยังไม่มีข้อมูล</span>
          </template>

          <template v-slot:[`item.receipt_date`]="{ item }">
            <v-row class="flex-nowrap items-center align-center pl-2">
              <div>
                {{ formatDate(item.receipt_date) }}
              </div>
            </v-row>
          </template>
          <template v-slot:[`item.order_num`]="{ item }">
            <v-row class="flex-nowrap items-center align-center pl-2">
              <v-chip @click="handleClick(item)" color="primary">
                {{ item.order_num }}
              </v-chip>
            </v-row>
          </template>
          <template v-slot:[`item.receipt_code`]="{ item }">
            <v-row class="flex-nowrap items-center align-center">
              <div class="font-weight-bold text-truncate pl-2" style="max-width: 180px">
                {{ item.receipt_code }}
              </div>
            </v-row>
          </template>
          <template v-slot:[`item.user`]="{ item }">
            <v-row class="flex-nowrap items-center align-center">
              <div class="text-truncate pl-2" style="max-width: 180px">{{ item.user.fname }} {{ item.user.lname }}</div>
            </v-row>
          </template>
          <template v-slot:[`item.phone`]="{ item }">
            <v-row class="flex-nowrap items-center align-center">
              <div class="pl-2">
                {{ item.user.phone }}
              </div>
            </v-row>
          </template>

          <template v-slot:[`item.total_all`]="{ item }">
            <v-row class="flex-nowrap items-center align-center">
              <div class="pl-2 font-weight-bold text--black">฿ {{ formatInt(item.total_all) }}</div>
            </v-row>
          </template>
          <template v-slot:[`item.total_pay`]="{ item }">
            <v-row class="flex-nowrap items-center align-center">
              <div v-if="item.status != 5" class="pl-2 font-weight-bold text--black">
                ฿ {{ formatInt(item.total_pay) }}
              </div>
              <div v-else class="pl-2 font-weight-bold text--red">฿ - {{ formatInt(item.total_pay) }}</div>
            </v-row>
          </template>
          <template v-slot:[`item.remain`]="{ item }">
            <v-row class="flex-nowrap items-center align-center">
              <div class="pl-2 font-weight-bold text--black">฿ {{ formatInt(item.remain) }}</div>
            </v-row>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-row class="flex-nowrap items-center align-center pl-2">
              <div v-if="item.status == 1" class="warning--text">
                <v-chip class="ma-2" color="#FEC400" label text-color="white" small>
                  <v-icon left small> mdi-alert-circle-outline </v-icon>
                  ชำระครั้งที่ {{ item.num_pay }}
                </v-chip>
              </div>
              <div v-else-if="item.status == 2" class="success--text">
                <v-chip class="ma-2" color="#43C50699" label text-color="white" small>
                  <v-icon left small> mdi-check </v-icon>
                  จ่ายครบ
                </v-chip>
              </div>
              <div v-else class="error--text">
                <v-chip class="ma-2" color="#9C0E05" label text-color="white" small>
                  <v-icon left small> mdi-arrow-u-right-top </v-icon>
                  ยกเลิก
                </v-chip>
              </div>
            </v-row>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-row class="pl-2 items-center align-center">
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleDetail(item.id)">
                    <v-list-item-title> <v-icon small> mdi-file-document </v-icon> รายละเอียด</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item @click="handleEdit(item.id)" v-show="item.status != 5">
                    <v-list-item-title class="warning--text">
                      <v-icon small color="warning"> mdi-pencil </v-icon> แก้ไข</v-list-item-title
                    >
                  </v-list-item> -->
                  <!-- <v-list-item v-show="item.status != 2 && item.status != 5">
                <v-list-item-title class="success--text">
                  <v-icon small color="success"> mdi-cash-multiple </v-icon> ชำระเงิน</v-list-item-title
                >
              </v-list-item> -->
                  <v-list-item @click="showCancel(item)" v-show="item.status != 5">
                    <v-list-item-title class="error--text">
                      <v-icon small color="error"> mdi-arrow-u-right-top </v-icon> ยกเลิก</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </template>
        </v-data-table>
        <div class="d-flex flex-row-reverse pt-2">
          <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
      </v-card-text>
    </v-card>
    <!-- dialog cancel -->
    <v-dialog v-model="dialogCancel" max-width="330px">
      <v-card>
        <v-card-title> <div class="text-left py-3" style="font-size: 22px">ยกเลิกการชำระ ?</div> </v-card-title>
        <v-card-subtitle>
          <div class="text-left pb-3">กรุณาระบุรหัสผ่านของคุณเพื่อยืนยันตัวตน ในการยกเลิกรายการชำระนี้</div>
          <v-textarea outlined label="เหตุผลในการยกเลิก" v-model="note_text"></v-textarea>
        </v-card-subtitle>
        <v-card-subtitle> <div class="error--text text-sm">รหัสผ่านของคุณ</div> </v-card-subtitle>
        <v-card-subtitle>
          <v-text-field
            v-model="password"
            label="password"
            solo
            :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showEye ? 'text' : 'password'"
            @click:append="showEye = !showEye"
          >
          </v-text-field>
          <div class="error--text">{{ resultCheckPass == 'error' ? 'รหัสผ่านไม่ถูกต้อง' : '' }}</div>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogCancel = false">ยกเลิก</v-btn>
          <v-btn color="primary" class="primary" @click="checkPassAdmin">ยืนยัน</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FormatNumber from '@/helper/function'
import moment from 'moment'
export default {
  data() {
    return {
      order_detail: '',
      user: '',
      listOrder: [],

      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'วันที่', align: 'left', value: 'receipt_date' },
        // { text: 'รหัสออเดอร์', align: 'left', value: 'order_num' },
        { text: 'รหัสคำสั่งซื้อ', align: 'left', value: 'receipt_code' },
        // { text: 'ลูกค้า', align: 'left', value: 'user' },
        // { text: 'เบอร์โทร', align: 'left', value: 'phone' },
        // { text: 'ยอดรวม', align: 'left', value: 'total_all' },
        { text: 'ชำระรวม', align: 'left', value: 'total_pay' },
        { text: 'สถานะ', align: 'left', value: 'status' },
        { text: 'ค้างชำระ', align: 'left', value: 'remain' },
        { text: 'จัดการ', align: 'left', value: 'action' },
      ],
      id_cancel: '',
      receipt_code: '',
      note_text: '',
      dialogCancel: false,
      password: '',
      resultCheckPass: '',
      showEye: false,
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? this.formatDate(this.date) : ''
    },
    filename() {
      return 'รายการบิล' + this.formatDate(this.date)
    },
    filteredItems() {
      return this.listOrder.filter(i => {
        return !this.selected || i.status == this.selected
      })
    },
  },
  mounted() {
    this.getOrderDetail()
  },
  methods: {
    formatInt(num) {
      return FormatNumber.formatInt(num)
    },
    formatFloat(num) {
      return FormatNumber.formatFloat(num)
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY HH:mm น.')
    },
    getOrderDetail() {
      var id = this.$route.params.id
      this.$store.dispatch('getOrderById', id).then(res => {
        console.log(res)
        this.order_detail = res
        this.listOrder = res.receipt
        this.user = res.user
      })
    },
    handleDetail(id) {
      this.$router.push('/list-order-daily/billing/' + id)
    },
    handleEdit(id) {
      this.$store.dispatch('getReceiptOrderById', id).then(order => {
        console.log(order)
        const order_list = []
        order.order_list.map(res => {
          console.log(res)
          if (res.remain != 0 && res.order_type != 'product') {
            Object.assign(res, { price_pay: parseInt(res.remain) })
            order_list.push(res)
          }
        })
        const data = {
          orders: order_list,
          total: order.remain,
          total_price: order.remain,
          data: order,
        }
        this.$store.commit('SET_ORDER', data)
        this.$router.push(`/openbill/detail/${order.order_id}`)
      })
    },
    showCancel(item) {
      this.id_cancel = item.id
      this.receipt_code = item.receipt_code
      console.log(this.receipt_code, 'this.receipt_code')
      this.dialogCancel = true
    },
    cancelItem() {
      var data = {
        id: this.id_cancel,
        note_text: this.note_text,
      }
      this.$store.dispatch('cancelOrder', data).then(res => {
        console.log(res)
        this.getOrderDetail()
        this.dialogCancel = false
        this.password = ''
        this.note_text = ''
      })
    },
    checkPassAdmin() {
      var data = {
        password: this.password,
      }
      this.$store.dispatch('checkPassword', data).then(res => {
        console.log(res.message)
        this.resultCheckPass = res.message
        if (this.resultCheckPass != 'error') {
          this.$store.dispatch('postCancelCommission', this.receipt_code).then(response => {
            if ((response = 'success')) {
              this.cancelItem()
            }
          })
        }
      })
    },
  },
}
</script>
